var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('FormulaBanner',{attrs:{"title":_vm.title,"formulaCode":_vm.formulaCode}}),_c('div',{staticClass:"index"},[_c('div',[_c('div',{staticClass:"top_nav"},[_c('span'),_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"formBody"},[_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('MyPatient',{attrs:{"memberId":_vm.memberId,"selectValue":_vm.selectValue},on:{"change":_vm.userChange}}),_c('van-field',{attrs:{"rules":[{ required: true, message: '请选择' }]},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"titles"},[_c('span',{staticClass:"titlesName"},[_vm._v("计算方式 "),_c('i',[_vm._v("*")])]),_c('span',{on:{"click":_vm.jisuanClick}},[_c('span',{staticClass:"information1"},[_c('span',{staticClass:"PleaseSelect",style:({
                        color:
                          _vm.jisuanfangshi === ''
                            ? 'rgb(166, 166, 166)'
                            : 'black',
                      })},[_vm._v(_vm._s(_vm.jisuanfangshi === "" ? "请选择" : _vm.jisuanfangshi))])]),_c('span',[_c('van-icon',{staticStyle:{"margin-left":"5px"},attrs:{"name":"arrow","color":"#A7A7A7","size":"17px"}})],1)])])]},proxy:true}]),model:{value:(_vm.jisuanfangshi),callback:function ($$v) {_vm.jisuanfangshi=$$v},expression:"jisuanfangshi"}}),(this.jisuanfangshi == '按体重减轻')?_c('van-field',{attrs:{"rules":[
              { required: true, message: '请填写' },
              {
                pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/,
                message: '请填写数字',
              },
            ]},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"titles"},[_c('span',{staticClass:"titlesName"},[_vm._v("原体重 "),_c('i',[_vm._v("*")])]),_c('span',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',{staticClass:"information"},[_c('van-cell-group',{staticClass:"vanCell"},[_c('van-field',{attrs:{"placeholder":"请输入"},model:{value:(_vm.yuantizhong),callback:function ($$v) {_vm.yuantizhong=$$v},expression:"yuantizhong"}})],1)],1),_c('div',{staticClass:"unit"},[_c('span',[_vm._v("kg")])])])])]},proxy:true}],null,false,3218777387),model:{value:(_vm.yuantizhong),callback:function ($$v) {_vm.yuantizhong=$$v},expression:"yuantizhong"}}):_vm._e(),(
              this.jisuanfangshi == '按体重减轻' ||
                this.jisuanfangshi == '按血细胞比容' ||
                this.jisuanfangshi == '按血钠浓度'
            )?_c('van-field',{attrs:{"rules":[
              { required: true, message: '请填写' },
              {
                pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/,
                message: '请填写数字',
              },
            ]},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"titles"},[_c('span',{staticClass:"titlesName"},[_vm._v("现体重 "),_c('i',[_vm._v("*")])]),_c('span',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"information"},[_c('van-cell-group',{staticClass:"vanCell"},[_c('van-field',{attrs:{"placeholder":"请输入"},model:{value:(_vm.xiantizhong),callback:function ($$v) {_vm.xiantizhong=$$v},expression:"xiantizhong"}})],1)],1),_c('div',{staticClass:"unit"},[_c('span',[_vm._v("kg")])])])])]},proxy:true}],null,false,4180816834),model:{value:(_vm.xiantizhong),callback:function ($$v) {_vm.xiantizhong=$$v},expression:"xiantizhong"}}):_vm._e(),(this.jisuanfangshi == '按血细胞比容')?_c('van-field',{attrs:{"rules":[
              { required: true, message: '请填写' },
              {
                pattern: /^(0(\.\d{1,2})?|1(\.0{1,2})?)$/,
                message: '请填写0~1之间的数字',
              },
            ]},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"titles"},[_c('span',{staticClass:"titlesName"},[_vm._v("血细胞比容 "),_c('i',[_vm._v("*")])]),_c('span',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"information"},[_c('van-cell-group',{staticClass:"vanCell"},[_c('van-field',{attrs:{"placeholder":"请输入"},model:{value:(_vm.xuexibaobirong),callback:function ($$v) {_vm.xuexibaobirong=$$v},expression:"xuexibaobirong"}})],1)],1)])])]},proxy:true}],null,false,538549563),model:{value:(_vm.xuexibaobirong),callback:function ($$v) {_vm.xuexibaobirong=$$v},expression:"xuexibaobirong"}}):_vm._e(),(this.jisuanfangshi == '按血钠浓度')?_c('van-field',{attrs:{"rules":[
              { required: true, message: '请填写' },
              {
                pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/,
                message: '请填写数字',
              },
            ]},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"titles"},[_c('span',{staticClass:"titlesName"},[_vm._v("血钠"),_c('i',[_vm._v("*")])]),_c('span',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"information"},[_c('van-cell-group',{staticClass:"vanCell"},[_c('van-field',{attrs:{"placeholder":"请输入"},model:{value:(_vm.xuenanongdu),callback:function ($$v) {_vm.xuenanongdu=$$v},expression:"xuenanongdu"}})],1)],1),_c('div',{staticClass:"unit"},[_c('span',[_vm._v("mmol/L")])])])])]},proxy:true}],null,false,2196864969),model:{value:(_vm.xuenanongdu),callback:function ($$v) {_vm.xuenanongdu=$$v},expression:"xuenanongdu"}}):_vm._e(),(
              this.jisuanfangshi == '按血钠浓度' ||
                this.jisuanfangshi == '按血细胞比容'
            )?_c('van-field',{attrs:{"rules":[{ required: true, message: '请选择' }]},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"titles"},[_c('span',{staticClass:"titlesName"},[_vm._v("性别 "),_c('i',[_vm._v("*")])]),_c('van-radio-group',{attrs:{"direction":"horizontal"},model:{value:(_vm.patientSex),callback:function ($$v) {_vm.patientSex=$$v},expression:"patientSex"}},[_c('van-radio',{attrs:{"name":"1"}},[_vm._v("男")]),_c('van-radio',{attrs:{"name":"2"}},[_vm._v("女")])],1)],1)]},proxy:true}],null,false,2501488774),model:{value:(_vm.patientSex),callback:function ($$v) {_vm.patientSex=$$v},expression:"patientSex"}}):_vm._e(),(_vm.compute === true)?_c('div',{staticStyle:{"margin":"16px","display":"flex","justify-content":"center"}},[_c('van-button',{staticClass:"formula-template-button",staticStyle:{"width":"90px"},attrs:{"block":"","type":"info","native-type":"submit"}},[_vm._v("提交")])],1):_vm._e()],1)],1)]),(_vm.show)?_c('CalculationResults',{attrs:{"countText":_vm.countText,"countTextList":_vm.countTextList}}):_vm._e(),_c('FormulaExplain',{attrs:{"explain-data":_vm.StandardDescription}}),_c('FormulaReferences',{attrs:{"references-data":_vm.references}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('van-picker',{attrs:{"title":"应激指数","show-toolbar":"","columns":_vm.columns},on:{"confirm":_vm.onConfirm,"cancel":_vm.onCancel}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }